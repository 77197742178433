import React from 'react';
import './internoPage.css';
import ParceiroComponent from './parceiroComponent';
import InternoHeader from './internoHeader';
import { firebaseapp, firebaseDB } from './firebaseConfig.js';
import $ from 'jquery';

class InternoPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            parceiros: []
          }

        document.title = "Loud - Site Interno";

        this.atualizaParceiros = this.atualizaParceiros.bind(this);
        this.addParceiro = this.addParceiro.bind(this);
        this.onChecked = this.onChecked.bind(this);

        var ref  = firebaseDB.collection('Parceiros').orderBy('categoria')
          .onSnapshot(this.atualizaParceiros);

    }

    componentDidMount(){
        firebaseDB.collection('Settings').doc('Manutencao').onSnapshot(doc =>{
            if(doc.data().state){
                $('#manutencaoCheckbox').prop("checked", true );
            }else{
                $('#manutencaoCheckbox').prop("checked", false );
            }
        });
    }

    atualizaParceiros(snapShot){
        var parceiros = [];
        var parceirosComponents = [];

        snapShot.forEach((doc) => {

            parceiros.push({
                id: doc.id,
                nome: doc.data().nome,
                categoria: doc.data().categoria
            });

        });

        parceiros.sort((aO,bO) => {

            var a = aO.nome;
            a = a.toLowerCase();

            var b = bO.nome;
            b = b.toLowerCase();

            if (a > b) {
                return 1;
              }
              if (a < b) {
                return -1;
              }
              // a must be equal to b
              return 0;

        });

        parceiros.forEach((doc) => {
            parceirosComponents.push(<ParceiroComponent id={doc.id} nome={doc.nome} categoria={doc.categoria} ></ParceiroComponent> );
        });

        this.setState({
            parceiros: parceirosComponents
        });
    }

    addParceiro(){
        window.open("/cadastro-de-parceiro","_self");
    }

    onChecked(){
        console.log('Manutencao:');
        console.log($('#manutencaoCheckbox').is(':checked'));

        firebaseDB.collection('Settings').doc('Manutencao').set({
            state: $('#manutencaoCheckbox').is(':checked')
        })
    }

    render(){
        return(
            <div className='internoPage'>
                <InternoHeader title='Página Interna'></InternoHeader>
                <div className='manutencaoSwitch'>
                    <h3>Manutenção</h3>
                    <label class="switch">
                        <input id='manutencaoCheckbox' type="checkbox" onChange={this.onChecked}></input>
                        <span class="slider round"></span>
                    </label>
                </div>
                {this.state.parceiros}
                <button className='addParceiroButton' onClick={this.addParceiro}>&#43;</button>
            </div>
           
        );
    }
}

export default InternoPage;
import React from 'react';
import './homePage.css';
import {firebaseDB} from './firebaseConfig';
import { slide as Menu } from "react-burger-menu";
import Logo from './images/logo_Loud_clara.png';
import LinhasTitulo from './images/linhas_Titulo.png';
import LinhasTituloClara from './images/linhas_Titulo_Clara.png';
import gasImage from './images/Gas.png';
import eletricistaImage from './images/Lampada.png';
import bombeiroHidraulicoImage from './images/Chave_Encanador.png';
import limpezaImage from './images/Limpeza_Icon.png';
import segurancaImage from './images/Seguranca_Icon.png';
import empresaPrediosImage from './images/Empresa_background_predios.png';
import checkImage from './images/check_Servicos.png';
// import servicoCardSeparator from './images/servico_card_separator.png';
import servicosAdministrativosIcon from './images/Servicos_Administrativos_icon.png';
import juridicoIcon from './images/Juridico_icon.png';
import cobrancaIcon from './images/Cobranca_icon.png';
import financeiroIcon from './images/Financeiro_icon.png';
import contabilidadeIcon from './images/Contabilidade_icon.png';
import localizacaoIconColors from './images/Localizacao_icon_colors.png';
import localizacaoIconClaro from './images/Localizacao_icon_claro.png';
import telefoneIcon from "./images/telefone_icon.png";
import telefoneIconCheio from "./images/telefone_icon_cheio.png";
import whatsappIcon from "./images/Whatsapp_icon.png";
import emailIcon from "./images/email_icon.png";
import menuIcon from "./images/menu_icon.png";
import facebookIcon from './images/facebook_icon.png';
import instagramIcon from "./images/instagram_icon.png";
import telefoneIconClaro from "./images/telefone_icon_claro.png";
import whatsappIconClaro from "./images/whatsapp_icon_claro.png";
import emailIconClaro from "./images/email_icon_claro.png";
import facebookIconClaro from './images/facebook_icon_claro.png';
import instagramIconClaro from "./images/instagram_icon_claro.png";

const textLorem = 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.';

const textoJuridico = 'Cobrança administrativa e judicial; Análise dos contratos de manutenção; Análise e cobrança de inadimplentes;';

const textoContabil = 'Elaboração de Balancete mensal de receitas e despesas; Prestação de contas do síndico, no encerramento da gestão;';

const textoFinanceiro = 'Cobrança com emissão de boleto bancário; Controle de pagamentos;';

const textoCobranca = 'Cobrança de inadimplentes; Cobrança administrativa e judicial; Cobrança com emissão de boleto bancário;';

const textoServicosAdministrativos = 'Elaboração de orçamentos e correspondências; Administração de quadro de pessoal; Coleta de preços para serviços e obras;';

const linkAreaCondomino = 'https://www.icondev.com.br/IconPortalCondomino/site/';

const telefone = '(37) 3259 0415';
const linkTelefone = 'tel:3732590415';

const whatsapp = '(37) 9 9119 0189';
const linkWhatsapp = 'https://api.whatsapp.com/send?phone=5537991190189';

const email = 'loudcondominios@gmail.com';
const linkEmail = 'mailto:' + email;

const facebook = '/loudcondominios';
const linkFacebook = 'https://www.facebook.com/Loud-Administra%C3%A7%C3%A3o-de-Condominios-110381000836606';

const instagramUser = 'loudcondominios';
const instagram = '@' + instagramUser;
const linkInstagram = 'https://instagram.com/' + instagramUser;

const endereco = 'R. Cap. João Teixeira, 148 - Nossa Sra. das Gracas, Pará de Minas - MG, 35660-051';
const linkEndereco = 'https://goo.gl/maps/oUje2QCmYiky23vHA';

const introducaoServicos = 'Independente do tamanho ou tipo do condomínio, ele deve ser sempre administrado de forma proficional. Oferecemos a você o que há de melhor em administração de condomínios e nosso papel exime os responsáveis de problemas desgastantes e complicações do dia-dia, confira nossos serviços:';

const parceirosFirebaseRef = firebaseDB.collection('Parceiros');

class ParceirosCard extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            parceiros: [],
            show: 'flex'
        }

        parceirosFirebaseRef.where('categoria', '==', this.props.categoria).get().then(snapShot =>{
            var parceirosComponents = [];
            var parceirosObjects = [];
            var showParceiro = 'flex';

            snapShot.forEach(doc =>{
                var parceiroObject = {
                    nome: doc.data().nome,
                    id: doc.id
                }
                parceirosObjects.push( parceiroObject);
            });

            // Coloca ParceirosObjects em ordem alfabética
            parceirosObjects.sort((aO,bO) => {

                var a = aO.nome;
                a = a.toLowerCase();
    
                var b = bO.nome;
                b = b.toLowerCase();
    
                if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
    
            });

            parceirosObjects.forEach(parca =>{
                parceirosComponents.push(<ParceiroCard nome={parca.nome} id={parca.id}></ParceiroCard>);
            });

            if (parceirosComponents.length === 0) {

                parceirosComponents = <div className="parceirosCard">Não há parceiros cadastrados no momento</div>;
                showParceiro = 'none';
            }

            this.setState({
                parceiros: parceirosComponents,
                show: showParceiro
            });
        });
    }

    render(){
        return(
            <div className="parceirosCard" style={{display: this.state.show}}>
                <img className="parceirosCardImage" src={this.props.image} alt={this.props.title}></img>
                <div className="parceirosCardTitle">{this.props.title}</div>
                <div className='parceirosList'>
                    {this.state.parceiros}
                </div>
                {/* <div className="parceiroCardSubTitle">{this.props.subTitle}</div>
                <div className="telefoneCardContainer" style={this.props.telefone != null ? {display : 'flex'} : {display : 'none'} }>
                    <img className="itemCardIcon" src={telefoneIcon}></img>
                    <div className="itemCardPhone">{this.props.telefone}</div>
                </div>
                <div className="whatsappCardContainer" style={this.props.whatsapp != null ? {display : 'flex'} : {display : 'none'} }>
                    <img className="itemCardIcon" src={whatsappIcon}></img>
                    <div className="itemCardPhone">{this.props.whatsapp}</div>
                </div> */}
            </div>
        );
    }
}

class ParceiroCard extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            contatos: []
        }
        
        parceirosFirebaseRef.doc(this.props.id).collection('Contatos').get().then(snapShot =>{
            var contatosComponents = [];

            snapShot.forEach(doc =>{
                console.log('Link: ' + doc.data().link);
                contatosComponents.push(<ParceiroContatos link={doc.data().link} celular={doc.data().celular} whatsapp={doc.data().whatsapp} email={doc.data().email} value={doc.data().value}></ParceiroContatos>);
            });

            this.setState({
                contatos: contatosComponents
            });
        });

    }
    render(){
        return(
            <div className='parceiroCard'>
                <div className='parceiroTitleContainer'>
                    <img className='parceiroImageCheck' src={checkImage} alt={this.props.nome}></img>
                    <div className='parceiroNome'>{this.props.nome}</div>
                </div>
                {this.state.contatos}
            </div>
        );
    }
}

class ParceiroContatos extends React.Component{
    constructor(props){
        super(props);

        this.formatarNumero = this.formatarNumero.bind(this);

        var celular = false;
        var whatsapp = false;
        var email = false;
        var linkType = null;
        var link = '';
        var style = {};
        var value = this.props.value;

        if(this.props.celular){
            celular = <img className="itemCardIcon" src={telefoneIcon} alt=''></img>;
            value = this.formatarNumero(value);
        }
        if(this.props.whatsapp) {
            whatsapp = <img className="itemCardIcon" src={whatsappIcon} alt=''></img>;
            value = this.formatarNumero(value);
        }
        if(this.props.email){
            email = <img className="itemCardIcon" src={emailIcon} alt=''></img>;
        }
        if(this.props.link){
            linkType = this.props.link;
            style['cursor'] = 'pointer';
        }

        switch(linkType){
            case 'celular':
                link = 'tel:' + this.props.value;
                break;
            case 'whatsapp':
                link = 'https://api.whatsapp.com/send?phone=55' + this.props.value;
                break;
            case 'email':
                link = 'mailto:' + this.props.value;
                break;
            default:
                link = '';
                break;
        }
        
        console.log('State link: ' + linkType + ': ' + link);

        this.state = {
            celular: celular,
            whatsapp: whatsapp,
            email: email,
            link: link,
            style: style,
            value: value
        }

    }

    formatarNumero(numero){
        
        var numeroString = '(' + numero.substr(0,2) + ') ' + numero.substr(2,1)  + ' ' + numero.substr(3,4)  + ' ' + numero.substr(7);
        
        if(numero.length == 10){
            numeroString = '(' + numero.substr(0,2) + ') ' + numero.substr(2,4)  + ' ' + numero.substr(6);
        }

        return numeroString;
    }

    render(){
        return(
            <div className='parceiroContato' display={this.state.cursor}>
                <a href={this.state.link}>
                    {this.state.celular}
                    {this.state.whatsapp}
                    {this.state.email}
                    {this.state.value}
                </a>
            </div>
        );
    }
}


class ServicosCard extends React.Component{
    render(){
        return(
            <div className="servicoCard">
                <img className="servicoCardImage servicoCardImageLeft" src={this.props.image} style={this.props.imageLeft ? {display: 'flex'} : {display: 'none'}} alt=''></img>
                <div className="cardContent">
                    <div className="titleCardContainer">
                        <img className="titleCardIcon" src={checkImage} alt=''></img>
                        <h2>{this.props.title}</h2>
                    </div>
                    <p>{this.props.content}</p>
                    
                    <hr></hr>
                    {/* <img className="servicoCardSeparator" src={servicoCardSeparator}></img> */}
                </div>
                <img className="servicoCardImage servicoCardImageRight" src={this.props.image} style={this.props.imageLeft ? {display: 'none'} : {display: 'flex'} } alt=''></img>
            </div>
        );
    }
}

class HomePage extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            menuOpen: false
          }
        document.title = "Loud - Administradora de Condomínios";
        
    }
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({menuOpen: false})
    }

    render(){
        return(
            <div className="homePage">
                <Menu isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)} id="myMenu" customBurgerIcon={ <img src={menuIcon} /> }>
                    <a className="menuItem menu-item" href="/"><img id="sideMenuLogo" className="logo" src={Logo} alt=''></img></a>
                    <a onClick={() => this.closeMenu()} className="menuItem menu-item" href="/#Parceiros">Parceiros</a>
                    <a onClick={() => this.closeMenu()} className="menuItem menu-item" href="/#Empresa">Empresa</a>
                    <a onClick={() => this.closeMenu()} className="menuItem menu-item" href="/#Servicos">Serviços</a>
                    <a onClick={() => this.closeMenu()} className="menuItem menu-item" href="/#Contato">Contato</a>
                    <a onClick={() => this.closeMenu()} className="menuItem menu-item" href={linkAreaCondomino}>Área do condômino</a>
                </Menu>
                {/* <img className="menuIcon" src={menuIcon}></img> */}
                <div className="contatosTopBar">
                    <div className="contatosTopBarLeft">
                        <a href={linkTelefone} className="contatosTopBarItem">
                            <img className="contatosTopBarItemImage" src={telefoneIconCheio} alt=''></img>
                            <div className="contatosTopBarItemDescription">{telefone}</div>
                        </a>
                        <a href={linkWhatsapp} className="contatosTopBarItem">
                            <img className="contatosTopBarItemImage" src={whatsappIcon} alt=''></img>
                            <div className="contatosTopBarItemDescription">{whatsapp}</div>
                        </a>
                        <a href={linkEmail} className="contatosTopBarItem">
                            <img className="contatosTopBarItemImage" src={emailIcon} alt=''></img>
                            <div className="contatosTopBarItemDescription">{email}</div>
                        </a>
                    </div>
                    <div className="contatosTopBarRight">
                        <a href={linkFacebook} className="contatosTopBarItem">
                            <img className="contatosTopBarItemImage" src={facebookIcon} alt=''></img>
                            <div className="contatosTopBarItemDescription">{facebook}</div>
                        </a>
                        <a href={linkInstagram} className="contatosTopBarItem">
                            <img className="contatosTopBarItemImage" src={instagramIcon} alt=''></img>
                            <div className="contatosTopBarItemDescription">{instagram}</div>
                        </a>
                    </div>
                </div>
                <div className="topBanner">
                    <div className="menuPrincipal">
                        <img className="logo" src={Logo}></img>
                        <div className="menuItens">
                            <a className="menuItem" href="#Parceiros">Parceiros</a>
                            <a className="menuItem" href="#Empresa">Empresa</a>
                            <a className="menuItem" href="#Servicos">Serviços</a>
                            <a className="menuItem" href="#Contato">Contato</a>
                            <a className="menuItem" href={linkAreaCondomino}>Área do condômino</a>
                        </div>
                    </div>
                    <div className="tituloBanner">Gestão de Condomínios</div>
                    <div className="subTituloBanner">Responsabilidade, ética e honestidade. Conheça os serviços que diminuem as dores de cabeça dos síndicos.</div>
                    <a className="bannerContatoButton" href="#Contato">Entre em contato</a>
                </div>
                <div id="Parceiros" className="partDiv">
                    <h1 >Parceiros Loud</h1>
                    <h3 >Proficionais parceiros da Loud Administradora de Condomínios para situações corriqueiras ou de urgência.</h3>
                    <img className="linhasTitulo" src={LinhasTitulo} alt=''></img>
                    <div className="parceirosItens">
                        <ParceirosCard image={bombeiroHidraulicoImage} title='Bombeiro Hidráulico' categoria='bombeiroHidraulico'></ParceirosCard>
                        <ParceirosCard image={eletricistaImage} title='Eletricista' categoria='eletricista'></ParceirosCard>
                        <ParceirosCard image={gasImage} title='Gás' categoria='gas' ></ParceirosCard>
                        <ParceirosCard image={limpezaImage} title='Limpeza e Conservação' categoria='limpeza'></ParceirosCard>
                        <ParceirosCard image={segurancaImage} title='Segurança' categoria='seguranca'></ParceirosCard>
                    </div>
                </div>
                <div id="Empresa" className="partDiv">
                    <h1 >Empresa</h1>
                    {/* <h3 className="empresaSubTitle"></h3> */}
                    <img className="linhasTitulo" src={LinhasTituloClara} alt=''></img>
                    <div id="descricaoEmpresa">
                        <p>
                        A <b>Loud Administração de Condomínios</b> é uma empresa sólida, baseado na eficiência, ética e profissionalismo.
                        </p>
                        <p>
                        A carência de soluções no mercado de administração de condomínios, visando uma consultoria completa na área, com apoio jurídico, contábil, administrativo e financeiro, fez com que a <b>Loud</b> não oferecesse apenas serviços de forma convencional, mas sim soluções para condomínios residenciais e comerciais que englobem todas as oportunidades e necessidades desse mercado.
                        </p>
                        <p>
                        Com uma filosofia de trabalho focada em soluções práticas na administração de condomínios, a <b>Loud</b> tem como meta sempre basear seu trabalho em custo-benefício, oferecendo aos clientes seriedade e transparência no acompanhamento do mesmo.
                        </p>
                        <h4>Missão</h4>
                        <p>
                        Oferecer soluções eficazes na Administração de Condomínios com seriedade e transparência. 
                        </p>
                        {/* <h4>Visão</h4>
                        <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
                        </p> */}
                        <h4>Valores</h4>
                        <p>
                        Ética, honestidade, segurança e transparência. 
                        </p>
                    </div>
                    <img id="empresaFoot" src={empresaPrediosImage} alt=''></img>
                </div>
                <div id="Servicos" className="partDiv">
                    <h1 >Serviços</h1>
                    {/* <h3 className="empresaSubTitle"></h3> */}
                    <img className="linhasTitulo" src={LinhasTitulo} alt=''></img>
                    <p>{introducaoServicos}</p>
                    <ServicosCard imageLeft={true} image={juridicoIcon} title='Acessoria Jurídica' content={textoJuridico}></ServicosCard>
                    <ServicosCard imageLeft={false} image={contabilidadeIcon} title='Contabilidade' content={textoContabil}></ServicosCard>
                    <ServicosCard imageLeft={true} image={financeiroIcon} title='Financeiro' content={textoFinanceiro}></ServicosCard>
                    <ServicosCard imageLeft={false} image={cobrancaIcon} title='Cobrança' content={textoCobranca}></ServicosCard>
                    <ServicosCard imageLeft={true} image={servicosAdministrativosIcon} title='Serviços Administrativos' content={textoServicosAdministrativos}></ServicosCard>
                </div>
                <div id="Contato" className="partDiv">
                    <h1 >Contato</h1>
                    {/* <h3 className="empresaSubTitle"></h3> */}
                    <img className="linhasTitulo" src={LinhasTitulo} alt=''></img>
                    <div id="contatosList">
                        <a className="contatoItem" href={linkTelefone}>
                            <div className="bordaContato">
                                <div id="bordaInterna" className="bordaContato">
                                    <img className="contatoImage" src={telefoneIcon} alt='Click para ligar'></img>
                                    <h3>Ligue</h3>
                                    <p>{telefone}<br></br>{whatsapp}<br></br>(37) 9 9976 1335</p>
                                    
                                </div>
                            </div>
                        </a>
                        <a className="contatoItem" href={linkWhatsapp}>
                            <div className="bordaContato">
                                <div id="bordaInterna" className="bordaContato">
                                    <img className="contatoImage" src={whatsappIcon} alt='Click para enviar uma mensagem por whatsapp'></img>
                                    <h3>Whatsapp</h3>
                                    <p>{whatsapp}</p>
                                </div>
                            </div>
                        </a>
                        <a className="contatoItem" href={linkEmail}>
                            <div className="bordaContato">
                                <div id="bordaInterna" className="bordaContato">
                                    <img className="contatoImage" src={emailIcon} alt='Click para enviar um email'></img>
                                    <h3>Email</h3>
                                    <p className="descricaoContato">{email}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div id="Endereco" className="partDiv">
                    <h1 >Endereço</h1>
                    {/* <h3 className="empresaSubTitle"></h3> */}
                    <img className="linhasTitulo" src={LinhasTitulo} alt=''></img>
                    <a href={linkEndereco} id="enderecoContainer">
                        <img id="localizacaoImage" src={localizacaoIconColors} alt='Click para obter a rotas para a Loud'></img>
                        <p>{endereco}</p>
                    </a>
                    <iframe title='Endereco' id="localizacaoMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.3692167737095!2d-44.60794048549142!3d-19.866625541471166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa73b5dbdb75b4b%3A0x634777659b0d40ac!2sLoud%20Administra%C3%A7%C3%A3o%20de%20Condom%C3%ADnios!5e0!3m2!1spt-BR!2sbr!4v1596047059686!5m2!1spt-BR!2sbr"  height="400" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    
                </div>
                <div id="footer">
                    <div id="footerContent">
                        <div className="logoFootContainer footPart">
                            <img id="logoFooter" src={Logo} alt='Loud'></img>
                        </div>
                        <div id="footContatos" className="footPart footContent">
                            <a className="footContentItem" href={linkTelefone}><div className="footIconContainer"><img className="footerContentIcon" src={telefoneIconClaro} alt=''></img></div> {telefone}</a>
                            <a className="footContentItem" href={linkWhatsapp}><div className="footIconContainer"><img className="footerContentIcon" src={whatsappIconClaro} alt=''></img></div> {whatsapp}</a>
                            <a className="footContentItem" href={linkEmail}><div className="footIconContainer"><img className="footerContentIcon" src={emailIconClaro} alt=''></img></div> {email}</a>
                            </div>
                        <div id="footRedesSociais" className="footPart footContent">
                            <a className="footContentItem" href={linkFacebook}><div className="footIconContainer"><img className="footerContentIcon" src={facebookIconClaro} alt=''></img></div> {facebook}</a>
                            <a className="footContentItem" href={linkInstagram}><div className="footIconContainer"><img className="footerContentIcon" src={instagramIconClaro} alt=''></img></div> {instagram}</a>
                        </div>
                        <div id="footEndereco" className="footPart footContent">
                            <a className="footContentItem" href={linkEndereco}><div className="footIconContainer"><img className="footerContentIcon" src={localizacaoIconClaro} alt=''></img></div> {endereco}</a>
                        </div>
                    </div>
                    <div id="direitosReservadosString">© 2020 Loud Administradora de Condomínios. Todos os direitos reservados.</div>
                </div>
            </div>
        );
    }
}

export default HomePage;
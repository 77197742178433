import React from 'react';
import './manutencaoPage.css';
import Logo from './images/logo_Loud_clara.png';

class ManutencaoPage extends React.Component{

    constructor(props){
        super(props);
        document.title = "Loud - Site em Manutenção";
        
    }

    render(){
        return(
            <div className='manutencaoPage'> 
                <img src={Logo} alt=''></img>
                <div className='manutencaoText'>Site em Manutenção! </div>
            </div>
        );
    }
}

export default ManutencaoPage;
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {firebaseapp, firebaseDB} from './firebaseConfig';
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
import HomePage from './homePage';
import LoginPage from './loginPage';
import InternoPage from './internoPage';
import ParceiroComponent from './parceiroComponent';
import {CadParceirosPage} from './cadParceirosPage';
import ManutencaoPage from './manutencaoPage';


class App extends React.Component{
  constructor(props){
    super(props);


    this.state = {
      loggedIn: false,
      manutencao: false
    }

    firebaseapp.auth().onAuthStateChanged( (user) =>{
      if(user){
        console.log('Index: Registrou logado');
        this.setState({
          loggedIn: true
        });
        
      }else{
        console.log('Index: Registrou deslogado');
        this.setState({
          loggedIn: false
        });
        
      }
    });


    firebaseDB.collection('Settings').doc('Manutencao').onSnapshot(doc =>{
      if (doc.exists) {
        this.setState({
          manutencao: doc.data().state
        });
      }
    });

  }

  render(){

    var page = null;
    if(this.state.manutencao){
      page = ManutencaoPage;
    }else{
      page = HomePage;
    }

    return(
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={page} />
        <Route path="/login" component={LoginPage} />
        {/* <Route path="/interno" component={this.state.loggedIn ? InternoPage: LoginPage} /> */}
        <ProtectedRoute path="/interno" logged={this.state.loggedIn} component={InternoPage}/>
        <ProtectedRoute path="/cadastro-de-parceiro" logged={this.state.loggedIn} component={CadParceirosPage}/>
      </Switch>
    </ BrowserRouter>
    );
  }
}




ReactDOM.render(
  <App></App>
  ,
  document.getElementById('root')
);


function ProtectedRoute({component: Component, logged, ...rest}){
    
  if(logged){
    console.log('MyRoute logado');
    return(<Route {...rest} component={Component}/> );
  }

  return(<Route {...rest} component={LoginPage}/> );

}
import React from 'react';
import './cadParceirosPage.css';
import { firebaseapp } from './firebaseConfig';
import { firebaseDB } from './firebaseConfig';
import $ from 'jquery';
import telefoneIcon from "./images/telefone_icon.png";
import whatsappIcon from "./images/Whatsapp_icon.png";
import emailIcon from "./images/email_icon.png";
import DeletarPopup from './deletarPopup';
import InternoHeader from './internoHeader';
import AddContatoPopup from './addContatoPopup';

class Contato {

    constructor(){
       this.celular = false; 
       this.whatsapp = false;
       this.email = false;
       this.value = '';
    }
    

}

class Parceiro{
    constructor(){
        this.id = null;
        this.nome = null;
        this.categoria = null;
        this.contatos = [];
    }
}


class ContatoCadParceirosPage extends React.Component{
    constructor(props){
        super(props);
        var celular = false;
        var whatsapp = false;
        var email = false;

        if(this.props.celular){
            celular = <img className="itemCardIcon" src={telefoneIcon}></img>;
        }
        if(this.props.whatsapp) {
            whatsapp = <img className="itemCardIcon" src={whatsappIcon}></img>;
        }
        if(this.props.email){
            email = <img className="itemCardIcon" src={emailIcon}></img>;
        }

        this.state = {
            celular: celular,
            whatsapp: whatsapp,
            email: email
        }

        this.deletarContatoPopup = this.deletarContatoPopup.bind(this);
        this.deletarContato = this.deletarContato.bind(this);
    }

    deletarContatoPopup(){
        document.getElementById(this.props.componentId).style.display = 'flex';
    }

    deletarContato(){
        this.props.onDelete(this.props.componentId);
    }

    render(){
        return(
            <div className='contatoCadParceirosPage'>
                <div className='contatoCadParceirosPageContent'>
                    {this.state.celular}
                    {this.state.whatsapp}
                    {this.state.email}
                    {this.props.value}
                </div>
                
                <button className='botaoDeletarParceiroComponent' onClick={this.deletarContatoPopup}>&times;</button>
                
                <DeletarPopup componentId={this.props.componentId} value={this.props.value} onSubmit={this.deletarContato} ></DeletarPopup>
            </div>
        );
    }
}


class CadParceirosPage extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            parceiro: new Parceiro(),
            categorias: [],
            contatosComponents: []
          }

        document.title = "Loud - Site Interno";

        this.getCategorias = this.getCategorias.bind(this);
        this.getParceiroFromFirebase = this.getParceiroFromFirebase.bind(this);
        this.getIdFromURL = this.getIdFromURL.bind(this);
        this.salvarParceiro = this.salvarParceiro.bind(this);
        this.atualizaParceiroView = this.atualizaParceiroView.bind(this);
        this.addContato = this.addContato.bind(this);
        this.addContatoPopup = this.addContatoPopup.bind(this);
        this.removeContato = this.removeContato.bind(this);
        this.nomeChanged = this.nomeChanged.bind(this);
        this.categoriaChanged = this.categoriaChanged.bind(this);
        this.salvarContatos = this.salvarContatos.bind(this);
      
        this.getCategorias();
        // this.getParceiro(this.getIdFromURL());
        var id = this.getIdFromURL();
        if(id != null){
            this.getParceiroFromFirebase(id);
            this.atualizaParceiroView();
        }


    }

    getCategorias(){
        firebaseDB.collection('Categorias').get().then(snapShot =>{

            var cats = [];

            snapShot.forEach(doc =>{
                cats.push( <option value={doc.data().categoria}>{doc.data().nome}</option>);
                console.log(doc.data().categoria);
            });

            this.setState({
                categorias: cats
            });

        });
    }

    getIdFromURL(){
        var searchString = this.props.location.search;
        console.log(searchString);
        const urlParams = new URLSearchParams(searchString);
        var id = urlParams.get('id');
        console.log('Id: ' + id);
        return id;
    }

    getParceiroFromFirebase(id){
            
        var parcaRef = firebaseDB.collection('Parceiros').doc(id);

        parcaRef.get().then(doc =>{
            
            var parceiro = new Parceiro();
            parceiro.id = id;
            parceiro.nome = doc.data().nome;
            parceiro.categoria = doc.data().categoria;
            if(doc.data().link){
                parceiro.link['categoria'] = doc.data().link.categoria;
                parceiro.link['value'] = doc.data().link.value;
            }
            

            this.setState({
                parceiro: parceiro
            });
            this.atualizaParceiroView();
        });

        parcaRef.collection('Contatos').get().then(snapShot =>{
                
            var contatos = [];
            
            snapShot.forEach(contatoDoc =>{

                var contato = new Contato();
                contato.id = contatoDoc.id;
                contato.value = contatoDoc.data().value;
                contato.celular = contatoDoc.data().celular;
                contato.whatsapp = contatoDoc.data().whatsapp;
                contato.email = contatoDoc.data().email;
                contato.firebaseRef = parcaRef.collection('Contatos').doc(contatoDoc.id);
                
                contatos.push(contato);
                
            });

            var parceiro = this.state.parceiro;
            parceiro.contatos = contatos;

            this.setState({
                parceiro: parceiro
            });

            this.atualizaParceiroView();
        });

            
    }

    atualizaParceiroView(){

        var parceiro = this.state.parceiro;

        if(parceiro != null){
            
            if(parceiro.id != null){
                $('#idLabel').val(parceiro.id);
            }else{
                $('#idLabel').val('Novo Parceiro');
            }
            
            $('#nomeInput').val(parceiro.nome);
            $('#categoriasSelect').val(parceiro.categoria);

            var contatosComponents = [];

            if(parceiro.contatos){
                parceiro.contatos.forEach((contato,index) =>{
                    var contatoComponent = <ContatoCadParceirosPage componentId={index} onDelete={this.removeContato} value={contato.value} celular={contato.celular} whatsapp={contato.whatsapp} email={contato.email}></ContatoCadParceirosPage>;
                    contatosComponents.push(contatoComponent);
                });
            }

            this.setState({
                contatosComponents: contatosComponents
            });
        }
    }

    addContatoPopup(){
        var modal = document.getElementById('addContatoPopup');
        modal.style.display = "flex";
    }

    addContato(novoContato){    
        if(novoContato){
            var parceiro = this.state.parceiro;
            parceiro.contatos.push(novoContato);
            this.setState({
                parceiro: parceiro
            });
            this.atualizaParceiroView();
        }
    }

    removeContato(index){

        var parceiro =  this.state.parceiro;
        if(parceiro.contatos){
            if(parceiro.contatos[index]){
                if(parceiro.contatos[index].id){
                    var contatoRef = firebaseDB.collection('Parceiros').doc(parceiro.id).collection('Contatos').doc(parceiro.contatos[index].id).delete();
                }
            }
        }

        parceiro.contatos.splice(index,1);
        this.atualizaParceiroView();

    }

    nomeChanged(){
        var parceiro = this.state.parceiro;
        parceiro.nome = $('#nomeInput').val();
        this.setState({
            parceiro: parceiro
        });
    }

    categoriaChanged(){
        var parceiro = this.state.parceiro;
        // parceiro.categoria = $('#categoriasSelect  option:selected').val();
        parceiro.categoria = $('#categoriasSelect').val();
        this.setState({
            parceiro: parceiro
        });
    }


    salvarParceiro(){

        var parca = {};

        if(this.state.parceiro){

            if(this.state.parceiro.nome){
                parca['nome'] = this.state.parceiro.nome;
            }else{
                alert('Nome Inválido');
                return;
            }
            if(this.state.parceiro.categoria){
                parca['categoria'] = this.state.parceiro.categoria;
            }else{
                alert('Categoria Inválida');
                return;
            }

            if(this.state.parceiro.contatos){
                if(this.state.parceiro.contatos.length == 0){
                    alert('Não há nenhum contato');
                    return;
                }
            }

            var parceirosRef = firebaseDB.collection('Parceiros');

            if(this.state.parceiro.id){
                console.log('Há id');
                parceirosRef.doc(this.state.parceiro.id).set(parca).then(() =>{
                    console.log('Salvo');
                    var parceiroRef = parceirosRef.doc(this.state.parceiro.id);
                    this.salvarContatos(parceiroRef);
                    window.open("/interno", "_self");
                }).catch(error =>{
                    alert('Erro ao salvar Parceiro! ', error );
                });
            }else{
                console.log('Não há id');
                parceirosRef.add(parca).then((doc) =>{
                    console.log('Salvo');
                    var parceiroRef = parceirosRef.doc(doc.id);
                    this.salvarContatos(parceiroRef);
                    window.open("/interno", "_self");
                }).catch(error =>{
                    alert('Erro ao adicionar Parceiro! ', error );
                });
            }
        }

        this.atualizaParceiroView();
    }

    salvarContatos(parceiroRef){
        if(this.state.parceiro){
            if(this.state.parceiro.contatos){
                // this.verificaContatosSalvos(parceiroRef);
                this.state.parceiro.contatos.forEach(contato =>{
                    var contatoContent = {};
                    if(contato.value){
                        contatoContent['value'] = contato.value;
                    }
                    if(contato.celular){
                        contatoContent['celular'] = contato.celular;
                    }
                    if(contato.whatsapp){
                        contatoContent['whatsapp'] = contato.whatsapp;
                    }
                    if(contato.email){
                        contatoContent['email'] = contato.email;
                    }

                    if(contato.link){
                        contatoContent['link'] = contato.link;
                    }

                    if(contato.id){
                        parceiroRef.collection('Contatos').doc(contato.id).set(contatoContent).then(() =>{
                            console.log('Contato atualizado');
                            return;
                        });
                    }else{
                        parceiroRef.collection('Contatos').add(contatoContent).then(doc =>{
                            console.log('Contato adicionado');
                            return;
                        });
                    }
                });
            }
        }
    }

    render(){

        var id = null;
        if(this.state.parceiro){
            id = this.state.parceiro.id;
        }

        return(
            <div className='cadParceirosPage'> 
                <InternoHeader title='Cadastro de Parceiro'></InternoHeader>
                
                <div className='cadParceiroForm'>
                    <label>Id:</label>
                    <label id='idLabel' name='idLabel'>{id}</label>
                    <label for='nomeInput'>Nome:</label>
                    <input type='text' id='nomeInput' name='nomeInput' onChange={this.nomeChanged}></input>
                    <label for='categoriasSelect'>Categoria:</label>
                    <select id='categoriasSelect' name='categoriasSelect' onChange={this.categoriaChanged}>
                        <option></option>
                        {this.state.categorias}
                    </select>
                    
                    <div className='cadParceiroFormRow'>
                        <label id='contatosLabel'>Contatos: </label>
                        <button id='addContatoButton' onClick={this.addContatoPopup}>Add Contato</button>
                    </div>
                    
                    
                    {this.state.contatosComponents}

                    <button id='salvarButton' onClick={this.salvarParceiro}>Salvar</button>
                </div>
                    
                <AddContatoPopup componentId='addContatoPopup' addContato={this.addContato}></AddContatoPopup>
               
            </div>
        );
    }
}

export {CadParceirosPage, Contato};
import React from 'react';
import './addContatoPopup.css';
import $ from 'jquery';
import {Contato} from './cadParceirosPage';

class AddContatoPopup extends React.Component{
    constructor(props){
        super(props);
        this.cancelar = this.cancelar.bind(this);
        this.addContato = this.addContato.bind(this);
        this.limparParametros = this.limparParametros.bind(this);
    }

    cancelar(){
        var modal = document.getElementById(this.props.componentId);
        modal.style.display = "none";
        this.limparParametros();
    }

    limparParametros(){
        $('#telefoneCheckbox').prop( "checked", false );
        $('#whatsappCheckbox').prop( "checked", false );
        $('#emailCheckbox').prop( "checked", false );

        $('input[name=contatoLinkRadio]').prop('checked', false);
        
        $('#addContatoPopupValue').val('');
    }

    addContato(){

        var contato = new Contato();

        contato.celular = $('#telefoneCheckbox').is(':checked');
        contato.whatsapp = $('#whatsappCheckbox').is(':checked');
        contato.email = $('#emailCheckbox').is(':checked');

        if($('input[name=contatoLinkRadio]').is(':checked')){
            contato.link = $('input[name=contatoLinkRadio]:checked').val();
        }
        
        contato.value = $('#addContatoPopupValue').val();

        if(contato.value == ''){
            alert('Campo não preenchido!');
            return;
        }

        this.props.addContato(contato);

        this.limparParametros();
        
        this.cancelar();
    }


    render(){
        return(
            <div id={this.props.componentId} className='addContatoPopup' >
                <div className='outAddContatoPopupContent' onClick={this.cancelar}></div>
                <div className='addContatoPopupContent' >
                    <div className='addContatoPopupHeader'>
                        <h2>Adicionar Contato</h2>
                        <span className='closeAddContatoPopup' onClick={this.cancelar}>&times;</span>
                    </div>
                    <div className='addContatoPopupBody'>
                        <div className='addContatoCheckboxes'>
                            <input id='telefoneCheckbox' type='checkbox'/><label for='telefoneCheckbox'>Telefone</label>
                            <input id='whatsappCheckbox' type='checkbox'/><label for='whatsappCheckbox'>Whatsapp</label>
                            <input id='emailCheckbox' type='checkbox'/><label id='emailCheckboxLabel' for='emailCheckbox'>Email</label>
                        </div>
                        <label>Contato:</label>
                        <input id='addContatoPopupValue' type='text'></input>
                        <label>Link:</label>
                        <div className='addContatoLinkRadioButtons'>
                            <div className='addContatoLinkRadioButtonItem'>
                                <input id='telefoneLinkRadioButton' name='contatoLinkRadio' type='radio' value='celular'/>
                                <label for='telefoneLinkRadioButton'>Telefone</label>
                            </div>
                            <div className='addContatoLinkRadioButtonItem'>
                                <input className='addContatoLinkRadioButton' id='whatsappLinkRadioButton' name='contatoLinkRadio' type='radio' value='whatsapp'/>
                                <label for='whatsappLinkRadioButton'>Whatsapp</label>
                            </div>
                            <div className='addContatoLinkRadioButtonItem'>
                                <input className='addContatoLinkRadioButton' id='emailLinkRadioButton' name='contatoLinkRadio' type='radio' value='email'/>
                                <label id='emailLinkLabel' for='emailLinkRadioButton'>Email</label>
                            </div>
                        </div>
                    </div>
                    <div className='addContatoPopupButtons'>

                        <button className='addContatoPopupButton' id='cancelarButton' onClick={this.cancelar}>Cancelar</button>
                        <button className='addContatoPopupButton' id='addButton' onClick={this.addContato}>Adicionar Contato</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddContatoPopup;
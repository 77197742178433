import React from 'react';
import { firebaseapp } from './firebaseConfig';
import './loginPage.css';
import logoImage from './images/Logo_Clara.png';
import $ from 'jquery';

class LoginPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            logged: false
          }
        document.title = "Loud - Login";

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.onLogoClick = this.onLogoClick.bind(this);
        this.limparCampos = this.limparCampos.bind(this);
        this.keyPressed = this.keyPressed.bind(this);

        firebaseapp.auth().onAuthStateChanged(function(user) {
            if (user) {
                // User is signed in.
                var displayName = user.displayName;
                var email = user.email;
                var emailVerified = user.emailVerified;
                var photoURL = user.photoURL;
                var isAnonymous = user.isAnonymous;
                var uid = user.uid;
                var providerData = user.providerData;

                console.log('logado!');

                // window.location.assign('/interno');
              // ...
            } else {
              // User is signed out.
              // ...

              console.log('Não está logado!');
            }
          });
        
    }

    limparCampos(){
      $('#userInput').val('');
      $('#passInput').val('');
    }

    login(){

        var email = $('#userInput').val();
        var password = $('#passInput').val();

        if(email == null){
          alert('Informe seu email de usuário!');
          return;
        }

        if(password == null){
          alert('Informe sua senha!');
          return;
        }

        console.log('Entrando em login');

        firebaseapp.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;

            if(errorCode == 'auth/invalid-email'){
              alert('Email inválido!');
            }

            if(errorCode == 'auth/user-not-found'){
              alert('Usuário não Encontrado!');
            }

            if(errorCode == 'auth/wrong-password'){
              alert('Senha inválida!');
              $('#passInput').val('');
              return;
            }

            $('#userInput').val('');
            $('#passInput').val('');

            console.log(errorCode);
            console.log(errorMessage);
            // ...
          });

        console.log('fechando login');
    }

    logout(){
        firebaseapp.auth().signOut().then(function() {
            // Sign-out successful.
            console.log('conseguiu deslogar');
          }).catch(function(error) {
            // An error happened.
            console.log(error);
          });
    }

    onLogoClick(){
      window.open("/", "_self");
    }

    keyPressed(event){
      var key = event.which || event.keyCode;
      if (key == 13) { // codigo da tecla enter
        this.login();
      }
    }

    render(){
        return(
            <div className='LoginPage'> 
              <img className='loginImageLogo' src={logoImage} onClick={this.onLogoClick}></img>
              <div className='loginItem'>
                <label for='userInput'>User:</label>
                <input className='loginInput' type='mail' id='userInput' name='userInput' placeholder='exemplo@gmail.com' onKeyPress={this.keyPressed}></input>

                <label for='passInput'>Senha:</label>
                <input className='loginInput' type='password' id='passInput' name='passInput' placeholder='senha' onKeyPress={this.keyPressed}></input>
              </div>
              
              <button className='loginItem' id='loginButton' onClick={this.login}>Login</button>

            </div>
        );
    }
}

export default LoginPage;
import React from 'react';
import './deletarPopup.css';

class DeletarPopup extends React.Component{
    constructor(props){
        super(props);
        this.cancelar = this.cancelar.bind(this);
    }

    cancelar(){
        var modal = document.getElementById(this.props.componentId);
        modal.style.display = "none";
    }

    render(){
        return(
            <div id={this.props.componentId} className='deletarPopup' onClick={this.cancelar}>
                <div className='deletarPopupContent'>
                    <div className='deletarPopupHeader'>
                        <h2>Deletar</h2>
                        <span className='closeDeletarPopup' onClick={this.cancelar}>&times;</span>
                    </div>
                    <div className='deletarPopupBody'>
                        <p>Tem certeza que deseja deletar: {this.props.value}</p>
                    </div>
                    <div className='deletarPopupButtons'>

                        <button className='deletarPopupButton' id='cancelarButton' onClick={this.cancelar}>Cancelar</button>
                        <button className='deletarPopupButton' id='deletarButton' onClick={this.props.onSubmit}>Deletar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeletarPopup;
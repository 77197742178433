import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCY1aeX1nwunw2e5qur-x1IkujIId2k94M",
    authDomain: "loud-condominios.firebaseapp.com",
    databaseURL: "https://loud-condominios.firebaseio.com",
    projectId: "loud-condominios",
    storageBucket: "loud-condominios.appspot.com",
    messagingSenderId: "676339162816",
    appId: "1:676339162816:web:5af3c10a74f1b5217f5725",
    measurementId: "G-FJW6ZJBM8T"
};

export const firebaseapp = firebase.initializeApp(config);
export const firebaseDB = firebase.firestore();
function logout(){
    firebaseapp.auth().signOut().then(function() {
        // Sign-out successful.
        console.log('conseguiu deslogar');
      }).catch(function(error) {
        // An error happened.
        console.log(error);
      });
}

export default logout;
import React from 'react';
import './parceiroComponent.css';
import { firebaseDB } from './firebaseConfig.js';
import DeletarPopup from './deletarPopup';
import telefoneIcon from "./images/telefone_icon.png";
import whatsappIcon from "./images/Whatsapp_icon.png";
import emailIcon from "./images/email_icon.png";


class Contato extends React.Component{
    constructor(props){
        super(props); 

        var celular = false;
        var whatsapp = false;
        var email = false;

        if(this.props.celular){
            celular = <img className="itemCardIcon" src={telefoneIcon} alt=''></img>;
        }
        if(this.props.whatsapp) {
            whatsapp = <img className="itemCardIcon" src={whatsappIcon} alt=''></img>;
        }
        if(this.props.email){
            email = <img className="itemCardIcon" src={emailIcon} alt=''></img>;
        }

        this.state = {
            celular: celular,
            whatsapp: whatsapp,
            email: email
        }
        // console.log('Contato log:');
        // console.log(this.props.numero);
    }

    render(){
        return(
            <div className='contatoParceiroComponent'>
                {this.state.celular}
                {this.state.whatsapp}
                {this.state.email}
                {this.props.value}
            </div>
        );
    }
}


class ParceiroComponent extends React.Component{

    constructor(props){
        super(props); 
        

        this.state = {
            contatosComponents: null
          }

        this.deletar = this.deletar.bind(this);
        this.getContatos = this.getContatos.bind(this);
        this.editParceiro = this.editParceiro.bind(this);
        this.deletarPopup = this.deletarPopup.bind(this);
        
        if(this.props.id){
            firebaseDB.collection('Parceiros').doc(this.props.id).collection('Contatos')
            .onSnapshot(this.getContatos);
        }
        

    }

    getContatos(snapShot){

        var lista = [];

        snapShot.forEach(doc =>{
            lista.push(<Contato value={doc.data().value} celular={doc.data().celular} whatsapp={doc.data().whatsapp} email={doc.data().email}></Contato> );
        });

        this.setState({
            contatosComponents: lista
        });

    }

    deletarPopup(){
        document.getElementById(this.props.id).style.display = 'flex';
    }

    deletar(){
        if(this.props.id){

            var parceirosRef = firebaseDB.collection('Parceiros');
            var parceiroRef = parceirosRef.doc(this.props.id);
            var contatosRef = parceiroRef.collection('Contatos');

            //Deletar os contatos primeiro
            contatosRef.get().then(snapShot =>{
                snapShot.forEach(doc =>{
                    contatosRef.doc(doc.id).delete().then(()=>{
                        console.log('Contato deletado com sucesso!');
                    }).catch(error =>{
                        console.log('Erro ao remover contato: ', error);
                    });
                });
            });

            parceiroRef.delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    }

    editParceiro(){
        window.open("/cadastro-de-parceiro?id=" + this.props.id, "_self");
    }

    render(){
        return(
            <div className='parceiroComponent'>
                <div id='dadosParceiroComponentColumn' className='parceiroComponentColumn'onClick={this.editParceiro}>
                    <div className='parceiroComponentRow'>
                        <div className='parceiroComponentLabel'>Id:</div>
                        <div className='parceiroComponentValue'>{this.props.id}</div>
                    </div>
                    <div className='parceiroComponentRow'>
                        <div className='parceiroComponentLabel'>Nome:</div>
                        <div className='parceiroComponentValue'>{this.props.nome}</div>
                    </div>
                    <div className='parceiroComponentRow'>
                        <div className='parceiroComponentLabel'>Categoria:</div>
                        <div className='parceiroComponentValue'>{this.props.categoria}</div>
                    </div>
                    <div className='parceiroComponentRow'>
                        <div className='parceiroComponentLabel'>Contatos:</div>
                        <div className='parceiroComponentValue'>{this.state.contatosComponents}</div>
                    </div>
                </div>
                <div className='parceiroComponentColumn'>
                    <button className='botaoDeletarParceiroComponent' onClick={this.deletarPopup}>&times;</button>
                </div>

                <DeletarPopup componentId={this.props.id} value={this.props.nome} onSubmit={this.deletar}></DeletarPopup>

            </div>
           
        );
    }
}

export default ParceiroComponent;
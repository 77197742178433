import React from 'react';
import './internoHeader.css';
import logout from './firebaseConfig';

class InternoHeader extends React.Component{
    constructor(props){
        super(props);

    }

    render(){
        return(
            <div className='internoHeader'>
                <h2>{this.props.title}</h2>
                <button onClick={logout}>Logout</button>
            </div>
        );
    }
}

export default InternoHeader;